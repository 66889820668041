/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import { Grid, Flex, Box, Heading, Text } from 'theme-ui';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../../plugins/storefront/src/components/Breadcrumbs';

const HowToChooseSizePage = (props) => {
  const { storeName } = props.data.site.siteMetadata.gatsbyStorefrontConfig;
  return (
    <>
      <Helmet
        title={t({
          id: `GuidesSize.Title`,
          message: `Size chart`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `GuidesSize.MetaDescription`,
            message: `Hair Monotapes Size Chart`,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            collectionTitle={t({
              id: `GuidesSize.BreadcrumbsParentTitle`,
              message: `Guides`,
            })}
            collectionPath={'/guides'}
            productTitle={t({
              id: `GuidesSize.BreadcrumbsTitle`,
              message: `Size chart`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Grid
        columns={[1, 2]}
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Box>
          <Heading as="h1" sx={{ fontSize: [4, 5] }}>
            <Trans id="GuidesSize.Header">Size chart</Trans>
          </Heading>

          <Text as="p" my={[2, 3]}>
            <Trans id="GuidesSize.Description">
              Kisscurls monotape is solid and flexible hair tape hand-crafted by
              professional wigmakers. Our work is so detailed, and each monotape
              is made carefully and with love.
            </Trans>
          </Text>

          <Trans id="GuidesSize.MainInformation">
            <Text as="p" mb={[2, 3]}>
              Each package contains four monotapes:
            </Text>
            <Text as="p" mb={[2, 3]}>
              <ul>
                <li>
                  Two side monotapes, each tape weft is 10 cm wide (3.9inches)
                </li>
                <li>15 cm wide tape weft (5.9inches)</li>
                <li>25 cm wide tape weft (9.8inches)</li>
              </ul>
            </Text>
            <Text as="p" mb={[2, 3]}>
              Each tape weft just about 1 mm thick and 1cm in height.
            </Text>
          </Trans>
          <Trans id="GuidesSize.40cmInformation">
            <Heading
              as="h2"
              color="primary"
              mt={[4]}
              mb={[3]}
              sx={{ fontSize: [3, 4] }}
            >
              40cm length package
            </Heading>
            <Text as="p" mb={[2]}>
              This package is ideally suited to make luxurious volume and
              non-extremal extension of your hair. Hair length achieves lower
              part of the shoulder blade. Total 60-70 grams of high-quality
              human hair per package. One pack is needed for a full application.
            </Text>
          </Trans>
          <Trans id="GuidesSize.50cmInformation">
            <Heading
              as="h2"
              color="primary"
              mt={[4]}
              mb={[3]}
              sx={{ fontSize: [3, 4] }}
            >
              50cm length package
            </Heading>
            <Text as="p" mb={[2]}>
              This package perfect for good extension and optimal volume. Hair
              length achieves your waist. Total 70-80 grams of high-quality
              human hair per package. One pack needed for a full application.
            </Text>
          </Trans>
          <Trans id="GuidesSize.60cmInformation">
            <Heading
              as="h2"
              color="primary"
              mt={[4]}
              mb={[3]}
              sx={{ fontSize: [3, 4] }}
            >
              60cm length package
            </Heading>
            <Text as="p" mb={[2]}>
              A 60 cm length pack is perfect for ladies who want to be
              magnificent and make a great impression. The highest length of
              silky hair will achieve your loin. Total 80-90 grams of
              high-quality human hair per package. One pack needed for a full
              application.
            </Text>
          </Trans>
        </Box>
        <Box>
          <StaticImage
            alt={t({
              id: `GuidesSize.HeroImageImageAlt`,
              message: `How to choose Hair Monotapes Size`,
            })}
            placeholder="blurred"
            layout="constrained"
            src="../../images/guides/how-to-choose-size-hero.jpg"
          />
        </Box>
      </Grid>
    </>
  );
};

export const HowToChooseSizePageQuery = graphql`
  query {
    site {
      siteMetadata {
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;

export default HowToChooseSizePage;
